
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import { useAuthStore } from "@/store/useAuthStore";
import type { UploadProps, UploadUserFile } from "element-plus";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import { hideModal } from "@/core/helpers/dom";
import { Plus } from "@element-plus/icons-vue";
interface IUploadFile {
  file: any;
  uid: number;
}
export default defineComponent({
  name: "edit-activity-post-modal",
  components: { Plus },
  emit: ["onSaveBtnClick"],
  setup(props, { emit }) {
    let thisModalObj;
    const authStore = useAuthStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const editActivityPostModalRef = ref<null | HTMLElement>(null);
    const acceptedTypes = ref(".jpeg,.jpg,.png");
    const maxFileSize = ref(5 * 1024 * 1024); // Specify the maximum file size in bytes (e.g., 5MB)
    const postFileTempList = ref<IUploadFile[]>([]);
    const uploadRef = ref();
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const deleteAttachmentIdList = ref<any[]>([]);
    const postAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activity-room-posts/attachments/"
    );

    const postData = ref<IActivityRoomPost>({
      id: null,
      activity_room: null,
      user: null,
      group: null,
      post: "",
      is_accept: false,
      comments: [],
      attachments: [],
      created_at: new Date(),
    });

    const fileList = ref<UploadUserFile[]>([]);

    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    const postRules = computed(() => {
      return {
        post: [
          {
            max: 255,
            message: "Message must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
      };
    });

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          var formData = new FormData();
          postFileTempList.value.forEach((postFile) => {
            formData.append("attachments[]", postFile.file);
          });
          deleteAttachmentIdList.value.forEach((attachmentId) => {
            formData.append("delete_attachment_id_list[]", attachmentId);
          });
          formData.append("postData", JSON.stringify(postData.value));
          ActivityRoomPostService.updatePost(formData)
            .then((response) => {
              postData.value.post = "";
              loading.value = false;
              uploadRef.value!.clearFiles();
              postFileTempList.value = [];
              Swal.fire({
                text: "Save complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              emit("onSaveBtnClick");
              hideModal(editActivityPostModalRef.value);
            })
            .catch(({ response }) => {
              loading.value = false;
              Swal.fire({
                title: "Error",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          return false;
        }
      });
    };

    const editPost = (post: IActivityRoomPost) => {
      postData.value.id = post.id;
      postData.value.post = post.post;
      postData.value.attachments = [];
      post.attachments!.forEach((attachment) => {
        postData.value.attachments?.push(attachment);
      });
      deleteAttachmentIdList.value = [];
    };

    const deletePostImage = (attachment) => {
      deleteAttachmentIdList.value.push(attachment.id);
      let deleteAttachmentIndex = postData.value.attachments!.findIndex(
        (obj) => obj.id === attachment.id
      );

      postData.value.attachments!.splice(deleteAttachmentIndex, 1);
    };

    const showModal = () => {
      thisModalObj.show();
    };

    const handleChange = (uploadFile) => {
      if (uploadFile) {
        const isAcceptedType = acceptedTypes.value
          .split(",")
          .some((type) => uploadFile.raw.name.toLowerCase().endsWith(type));
        const isSizeValid = uploadFile.size <= maxFileSize.value;

        if (!isAcceptedType) {
          Swal.fire({
            title: "Warning",
            text: "Only supports .jpeg, .jpg, .png file types.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        if (!isSizeValid) {
          Swal.fire({
            title: "Warning",
            text:
              "Max file size is " + maxFileSize.value / (1024 * 1024) + "MB",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (isAcceptedType && isSizeValid) {
          let reader = new FileReader();
          reader.readAsDataURL(uploadFile.raw);
          reader.onload = (e) => {
            postFileTempList.value.push({
              file: uploadFile.raw,
              uid: uploadFile.uid,
            });
          };
        } else {
          uploadRef.value.handleRemove(uploadFile);
        }
      }
    };

    const handleRemove: UploadProps["onRemove"] = (uploadFile, uploadFiles) => {
      let index = postFileTempList.value.findIndex(
        (obj) => obj.uid === uploadFile.uid
      );
      if (index != -1) {
        postFileTempList.value.splice(index, 1);
      }
    };

    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      dialogVisible.value = true;
    };

    onMounted(() => {
      thisModalObj = new Modal(editActivityPostModalRef.value);
    });

    return {
      loading,
      formRef,
      editActivityPostModalRef,
      showModal,
      postData,
      onSubmit,
      postRules,
      currentUser,
      acceptedTypes,
      uploadRef,
      handleRemove,
      handlePictureCardPreview,
      dialogVisible,
      dialogImageUrl,
      handleChange,
      postFileTempList,
      postAttachmentbaseURL,
      fileList,
      editPost,
      deletePostImage,
    };
  },
});
