
import { IActivityRoomComment } from "@/core/data/activityRoomComment";
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import { IActivityPostLike } from "@/core/data/activityPostLike";
import ActivityRoomCommentService from "@/core/services/ActivityRoomCommentService";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import EditActivityPostModal from "@/views/studio-lab/components/modals/EditActivityPostModal.vue";
import { useAuthStore } from "@/store/useAuthStore";
import Swal from "sweetalert2";
import CommentCard from "@/views/studio-lab/components/cards/CommentCard.vue";
import ViewImageModal from "@/views/studio-lab/components/modals/ViewImageModal.vue";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
// import { MenuComponent } from "@/assets/ts/components/index";
import moment from "moment";

export default defineComponent({
  components: { CommentCard, EditActivityPostModal, ViewImageModal },
  props: {
    currentPost: Object as PropType<IActivityRoomPost>,
    isTeacher: Boolean,
  },
  emit: ["onRefreshPost"],
  setup(props, { emit }) {
    const authStore = useAuthStore();
    const currentComment = ref("");
    const isLike = ref<boolean>(false);
    const isPostOwner = ref<boolean>(false);
    const activityPostLikes = ref<Array<IActivityPostLike>>([]);
    const postAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activity-room-posts/attachments/"
    );

    const imgAttached = ref<Array<number>>([]);
    const getImgList = () => {
      imgAttached.value.splice(0, imgAttached.value.length);
      props.currentPost?.attachments?.forEach((item) => {
        imgAttached.value.push(item.id ?? -1);
      });
    };
    const viewImageModalRef = ref();
    const showImage = (attachments) => {
      imgAttached.value.splice(0, imgAttached.value.length);
      attachments.forEach((attachment) => {
        imgAttached.value.push(attachment.id);
      });

      viewImageModalRef.value.showModal();
    };

    const editActivityPostModalRef = ref();
    const editPost = () => {
      editActivityPostModalRef.value.editPost(props.currentPost);
      editActivityPostModalRef.value.showModal();
    };

    const onRefreshPost = () => {
      emit("onRefreshPost");
    };

    const formatDateTime = (value) => {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      } else return "-";
    };

    const initLikes = () => {
      ActivityRoomPostService.getPostLikeByPostId(props.currentPost?.id).then(
        (response) => {
          activityPostLikes.value = response.data;

          let activityPostLike = activityPostLikes.value.find(
            (obj) => obj.user?.id === authStore.currentUser?.id
          );

          isLike.value = activityPostLike != undefined ? true : false;
        }
      );
    };
    const likePost = () => {
      isLike.value = !isLike.value;

      if (isLike.value) {
        let postLike = ref<IActivityPostLike>({
          id: null,
          post: props.currentPost!,
          user: authStore.currentUser!,
        });
        ActivityRoomPostService.likePost(postLike.value).then((response) => {
          initLikes();
        });
      } else {
        ActivityRoomPostService.unlikePost(props.currentPost?.id).then(
          (response) => {
            initLikes();
          }
        );
      }
    };
    const acceptPost = () => {
      if (props.currentPost?.is_accept) {
        ActivityRoomPostService.unAcceptPost(props.currentPost?.id).then(
          (response) => {
            onRefreshPost();
          }
        );
      } else {
        ActivityRoomPostService.acceptPost(props.currentPost?.id).then(
          (response) => {
            onRefreshPost();
          }
        );
      }
    };
    const deletePost = (post) => {
      Swal.fire({
        title: `Would you like to delete this post?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          ActivityRoomPostService.deletePost(post?.id)
            .then((response) => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              onRefreshPost();
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    const addComment = () => {
      if (currentComment.value != "" && currentComment.value != undefined) {
        let comment = ref<IActivityRoomComment>({
          id: null,
          post: props.currentPost!,
          user: authStore.currentUser!,
          comment: currentComment.value,
          created_at: new Date(),
        });

        ActivityRoomCommentService.createComment(comment.value)
          .then((response) => {
            currentComment.value = "";
            onRefreshPost();
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    watch(
      () => authStore.currentUser,
      (newValue, oldValue) => {
        isPostOwner.value =
          props.currentPost?.user?.id == authStore.currentUser?.id
            ? true
            : false;
      }
    );

    onMounted(() => {
      initLikes();
      getImgList();
      // MenuComponent.reinitialization();
      isPostOwner.value =
        props.currentPost?.user?.id == authStore.currentUser?.id ? true : false;
    });

    return {
      deletePost,
      editPost,
      addComment,
      currentComment,
      isLike,
      likePost,
      initLikes,
      activityPostLikes,
      onRefreshPost,
      postAttachmentbaseURL,
      currentUser,
      acceptPost,
      editActivityPostModalRef,
      viewImageModalRef,
      showImage,
      imgAttached,
      formatDateTime,
      isPostOwner,
    };
  },
});
