
import { defineComponent, onMounted, ref, PropType, watch } from "vue";
import { Modal } from "bootstrap";
import { useAuthStore } from "@/store/useAuthStore";
export default defineComponent({
  name: "view-image-modal",
  props: { baseUrl: String, imgIds: Object as PropType<Array<number>> },
  emit: ["onSaveBtnClick"],
  setup(props, { emit }) {
    let thisModalObj;
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const authStore = useAuthStore();
    const viewImageModalRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      thisModalObj = new Modal(viewImageModalRef.value);
    });

    const showModal = () => {
      thisModalObj.show();
    };

    const imgIndex = ref({
      index: 0,
      max: 0,
    });

    const nextImg = () => {
      if (imgIndex.value.index + 1 >= imgIndex.value.max) {
        imgIndex.value.index = 0;
      } else {
        imgIndex.value.index++;
      }
    };

    const prevImg = () => {
      if (imgIndex.value.index - 1 < 0) {
        imgIndex.value.index = imgIndex.value.max - 1;
      } else {
        imgIndex.value.index--;
      }
    };

    watch(
      () => props.imgIds?.length,
      (newValue, oldValue) => {
        imgIndex.value.max = newValue ?? 0;
      }
    );

    onMounted(() => {
      imgIndex.value.max = props.imgIds?.length ?? 0;
    });

    return {
      loading,
      formRef,
      viewImageModalRef,
      imgIndex,
      nextImg,
      prevImg,
      showModal,
    };
  },
});
