
import { IActivityRoomComment } from "@/core/data/activityRoomComment";
import ActivityRoomCommentService from "@/core/services/ActivityRoomCommentService";
import { useAuthStore } from "@/store/useAuthStore";
import moment from "moment";
import Swal from "sweetalert2";
import { defineComponent, onMounted, PropType, ref, watch } from "vue";

export default defineComponent({
  props: {
    currentComment: Object as PropType<IActivityRoomComment>,
  },
  emit: ["onRefreshPost"],
  setup(props, { emit }) {
    const authStore = useAuthStore();
    const isCommentOwner = ref<boolean>(false);

    const deleteComment = (comment) => {
      Swal.fire({
        title: `Would you like to delete this comment?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          ActivityRoomCommentService.deleteComment(comment?.id)
            .then(() => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              emit("onRefreshPost");
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    
    const formatDateTime = (value) => {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      } else return "-";
    };

    watch(
      () => authStore.currentUser,
      (newValue, oldValue) => {
        isCommentOwner.value =
          props.currentComment?.user?.id == authStore.currentUser?.id
            ? true
            : false;
      }
    );

    onMounted(() => {
      isCommentOwner.value =
        props.currentComment?.user?.id == authStore.currentUser?.id
          ? true
          : false;
    });

    return { formatDateTime, deleteComment, isCommentOwner };
  },
});
